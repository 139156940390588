import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from 'vuex-map-fields';

import user from './modules/user'
import app from './modules/app'
import warehouse from './modules/warehouse'
import product from './modules/product'
import order from './modules/order'
import shipping from './modules/shipping'
import custom_scripts from './modules/custom_scripts'
import fulfillments from './modules/fulfillments'
// import campaigns from './modules/campaigns'
// import teams from './modules/teams'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {
    getField,
  },
  mutations: {
    updateField,
  },
  modules: {
    user, 
    app,
    warehouse,
    product,
    order,
    shipping,
    custom_scripts,
    fulfillments,
  }
});
