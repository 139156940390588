// import store from '@/store'
import Firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import store from "@/store";


const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
   };
   
export default {
  install: (Vue) => {
    Vue.prototype.$appMode = process.env.VUE_APP_MODE
    Vue.prototype.$appName = process.env.VUE_APP_NAME
    Vue.prototype.$appUrl = process.env.VUE_APP_URL
    const firebase = Firebase.initializeApp(config)
    const db = firebase.firestore();
    if(process.env.NODE_ENV === 'development'){
      firebase.functions().useFunctionsEmulator("http://localhost:5002")
      db.settings({ 
        host: 'localhost:8070',
        ssl: false
      })
      Vue.prototype.$functions = firebase.functions() 
    } else {Vue.prototype.$functions = Firebase.app().functions('europe-west3')}
    
    const auth = firebase.auth()

    // auth.onAuthStateChanged((user) => {
    //   // console.log('auth', user);
    //   if(!auth) return
    //   store.dispatch('user/get_user', user)
    // })
    
    Vue.prototype.$auth = {
      login: async (username, pass) => {
        // await auth.setPersistence(Firebase.auth.Auth.Persistence.SESSION)
        const user = await auth.signInWithEmailAndPassword(username, pass)
        return user
      },
      logout: async () => {
        store.dispatch('app/reset_state')
        await auth.signOut()
      },
      signup: async (email, password) => {
        // await auth.setPersistence(Firebase.auth.Auth.Persistence.SESSION)
        const user = await auth.createUserWithEmailAndPassword(email, password)
        auth.currentUser.sendEmailVerification()
        // const id = auth.currentUser.uid
        // await store.dispatch('user/get_user', { id, email, name })
        return user
      }
    }
  },
}
