import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/firestore';

const initial_state = () => ({ 
    loading: false,
    logged_camera: false,
    show_session_expiration_dialog: false,
    snackBar: {
        text: '',
        display: false,
      },
      drawer: false,
      error: null,
      print_src: false,
      session_id: '0',
      loading_message: '',
      config: {
        plan_limits: [],
        addon_expected_values: [] ,
        addons: {},
        label_formats: {},
        plans: [],
      },
      
})

const state = initial_state()

const actions = {
  async initiate({ commit }){
    commit('SET_SESSION_ID', new Date().getTime().toString())
    const config = await firebase.firestore().collection('config').doc('config').get().then(res => res.data())
    commit('SET_STATE', { config })
  },
  reset_state({ commit }){
    commit('order/RESET_STATE', null, { root: true })
    commit('product/RESET_STATE', null, { root: true })
    commit('shipping/RESET_STATE', null, { root: true })
    commit('user/RESET_STATE', null, { root: true })
    commit('warehouse/RESET_STATE', null, { root: true })
    commit('RESET_STATE')
  },
  async submit_support_ticket({ rootState }, { email, question }){
    const company_id = rootState.warehouse.current_company_id
    const submit_support_ticket = this._vm.$functions.httpsCallable('submit_support_ticket')
    await submit_support_ticket({ company_id, email, question })
  },
  async submit_error({ rootState }, { e, within } ){
    const x = e.stack ? e.stack : JSON.stringify(e)
    const message = `within: ${within} // Error: ${x}`
    const company_id = rootState.warehouse.current_company_id
    const submit_support_ticket = this._vm.$functions.httpsCallable('submit_support_ticket')
    await submit_support_ticket({ company_id, message })
  },
  async submit_log({ rootState }, { message }){
    const company_id = rootState.warehouse.current_company_id
    const submit_support_ticket = this._vm.$functions.httpsCallable('submit_support_ticket')
    await submit_support_ticket({ company_id, message })
  },
  /* eslint-disable no-empty-pattern */
  async get_subscription({  }, charge_id){
    const get_shopify_subscription = this._vm.$functions.httpsCallable('get_shopify_subscription')
    const res = await get_shopify_subscription({ charge_id }).then(res => res.data)
    return res
  }
}

const mutations = {
    SET_PRINT_SRC (state, payload) {
      Vue.set(state, 'print_src', payload)
    },
    SET_SNACK_BAR (state, text) {
       Vue.set(state, 'snackBar', {display: true, text})
      },
      setLoading (state, payload) {
        Vue.set(state, 'loading', payload)
      },
      SET_DRAWER (state, payload) {
        state.drawer = payload
      },
      SET_ERROR (state, e){
        Vue.set(state, 'snackBar', {display: true, text: e.message})
      },
      SET_SESSION_ID(state, payload){
        Vue.set(state, 'session_id', payload)
      },
      SET_STATE(state, payload){
        for(const key in payload){
          Vue.set(state, key, payload[key])
        }
      },
      RESET_STATE(state){
        for(const key in initial_state()){
          Vue.set(state, key, initial_state()[key])
        }
      }
}

const getters = {
  get_onboarding_tasks: (state, getters, rootState, rootGetters) => {
    let lowest_current_group = Infinity
    const tasks = [
      {
        header: 'Where are you fulfilling orders from?',
        description: 'Let us know from where you are fulfilling orders',
        done: !rootState.warehouse.company.data_sources.some(ds => !ds.location_id),
        group: -1,
        button_text: 'Choose',
        button_action: 'fulfillment_location'
      },
      {
        header: 'Set up a carrier account',
        description: 'Link your Postnord or nShift account to Fulfillment Hero',
        done: !!rootState.warehouse.company.carriers.length,
        group: 0,
        button_text: 'Setup',
        button_action: 'carrier'
      },
      {
        header: 'Setup shipping methods',
        // description: 'Decide which customer facing shipping method correlates with your carriers shipping methods',
        description: 'Map your manual shipping methods or setup app based carrier rates for your Store check out',
        done: !!rootState.warehouse.company.data_sources[0].shipping_map.length || !rootState.warehouse.company.data_sources.some(ds => !ds.uses_shopify_labels),
        group: 1,
        button_text: 'Shipping',
        button_action: 'shipping'
      },
      {
        header: 'Activate trial',
        description: 'Choose plan and activate your 30 day free trial',
        done: rootGetters['warehouse/get_plan']?.id > 0,
        group: 1,
        button_text: 'View plans',
        button_action: 'plans'
      },
      {
        header: 'Draw up your warehouse',
        description: 'Add your shelves and map out your products for efficient order picks',
        done: rootState.warehouse.shelves.length > 1,
        group: 2,
        button_text: 'Setup',
        button_action: 'warehouse'
      },
      {
        header: 'Setup label printer',
        description: 'Automate your label printing',
        done: !!rootState.warehouse.company.printserver_id,
        group: 3,
        button_text: 'Printer settings',
        button_action: 'printers'
      },
      {
        header: 'Create staff accounts',
        description: 'Create separate accounts for your staff so they can fulfill orders using their phones, without accessing Shopify.',
        done: rootState.warehouse.company.users.length > 1,
        group: 3,
        optional: true,
        button_text: 'Invite staff',
        button_action: 'staff'
      }

    ]
    tasks.forEach(task => {
      if(!task.done && task.group < lowest_current_group) lowest_current_group = task.group
    })
    tasks.sort((a, b) => b.done - a.done)
    return tasks.map(task => ({ ...task, current: task.group === lowest_current_group}))
  },
  get_plan_by_title: (state) => (title) => {
    return state.config.plan_limits.reduce((tot, scope) => ({...tot, [scope.key]: scope[title] }), {})
  },
  get_plan_conflicts: (state, getters, rootState, rootGetters) => title => {
    const scopes = getters.get_plan_by_title(title)
    const store = rootGetters['warehouse/get_main_store']
    const warehouse = rootState.warehouse
    const company = warehouse.company
    const ret =  {
      amount_shelves: title !== 'transfer' && (scopes.max_shelves < warehouse.shelves.length),
      store_transfer: (!scopes.allow_store_transfer && (store.fulfilling_company_id !== store.parent_company_id)),
      order_bucket_size: title !== 'transfer' && (scopes.max_order_bucket_size < company.order_bucket_size),
      connected_stores: (scopes.max_connected_stores < company.data_sources.length),
      amount_staff: (scopes.max_staff < (company.users.length + company.invitations.length - 1)),
      carrier_accounts: (scopes.max_carriers < company.carriers.length),
      allow_shelf_scanning: !scopes.allow_shelf_scanning && company.require_shelf_scanning,
      allow_product_scanning: !scopes.allow_product_scanning && company.require_item_scanning,
      allow_auto_carrier: !scopes.allow_auto_carrier && company.data_sources
        .some(ds => ds.auto_carrier_services
          .some(acs => acs.active_services.length))
    }
    const i = []
    for(const key in ret) if(ret[key]) i.push(key)
    return i
  },
  get_available_print_formats: (state, getters, rootState) => {
    return rootState.warehouse.company.carriers.reduce((tot, carrier) => (
      tot.concat(state.config.label_formats[carrier.type])
    ), [])
  },
  get_carrier_print_format: (state, getters, rootState) => (carrier_id) => {
    return state.config.label_formats[rootState.warehouse.company.carriers.find(c => c.id === carrier_id).type]
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
