<template>
<v-app>
    <router-view v-if="!loading" />
    <div class="text-center mx-auto mt-12" v-else>
    <v-progress-circular
    color="primary"
    indeterminate
    size="50"
     />
      <p class="text-body-2">{{ loading_message }}</p>
    </div>
    <SnackBar />
    <SwitchUserModal />
    <!-- <PrintView v-if="$store.state.app.print_src" /> -->
</v-app>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
    SnackBar: () => import('@/components/SnackBar.vue'),
    SwitchUserModal: () => import('@/components/SwitchUserModal.vue')
    // PrintView: () => import('@/components/PrintView.vue')
  },
  data() {
    return {
    
    }
  },
  computed: {
    ...mapState({
      loading_message: state => state.app.loading_message,
      loading: state => state.app.loading,
    })
  },
  async mounted(){
    this.$store.dispatch('app/initiate')
  }
  
}
</script>

<style lang="scss">

  .v-application {
    font-family: 'Boing', boing, sans-serif !important;
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    }
    
    @font-face {
      font-family: 'Boing'; 
      src: url('./assets/fonts/boing-regular.woff2');
    }

</style>

