import Vue from 'vue'
// import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore';

const initial_state = () => ({ 
    parcels: [],
    ds_last_fetch: {},
})

const state = initial_state()

const actions = {
  async fetch_parcels({ rootState, commit, state }, { rows }){
    const limit = rows - state.parcels.length
    console.log(limit);
    if(limit < 1) return
    const ds_parcels = await Promise.all(
        rootState.warehouse.company.data_sources
          .filter(ds => ds.fulfilling_company_id === rootState.warehouse.current_company_id)
          .map(ds => firebase.firestore()
            .collection('data_sources')
            .doc(ds.id)
            .collection('fulfillments')
            .orderBy('updated_at', 'desc')
            .startAfter(state.ds_last_fetch[ds.id] || new Date('2999-01-01').toISOString())
            .limit(limit)
            .get()
            .then(async res => 
              {
                const docs = await Promise.all(res.docs.map(d => ({
                  ...d.data(),
                  sf_ds_id: ds.id,
                  sf_ds_name: ds.name,
                  sf_fulfillment_id: d.id
                })))
                return { parcels: docs, last_fetch: { ds_id: ds.id, date: docs.length && docs[docs.length - 1].updated_at }}
              }
              )
            )
      ) // .then(p => [].concat(...p))
      const parcels = state.parcels.concat(ds_parcels.reduce((tot, p) => tot.concat(...p.parcels), []))
      parcels.sort((a, b) => b.created_at - a.created_at)
      const last_fetches = {}
      ds_parcels.forEach(ds => {
        if(!ds.last_fetch.date) return
        last_fetches[ds.last_fetch.ds_id] = ds.last_fetch.date
      })
      commit('SET_STATE', { parcels, ds_last_fetch: {
        ...state.ds_last_fetch,
        ...last_fetches,
      }})
  }
}

const mutations = {
   
      SET_STATE(state, payload){
        for(const key in payload){
          Vue.set(state, key, payload[key])
        }
      },
      RESET_STATE(state){
        for(const key in initial_state()){
          Vue.set(state, key, initial_state()[key])
        }
      },
      UNSHIFT_PARCEL(state, payload){
        state.parcels.unshift(payload)
      }
}

const getters = {
  get_server_fulfillments: (state, getters, rootState) => {
    return rootState.warehouse.company.data_sources.reduce((tot, ds) => tot += ds.fulfillments || 0, 0)
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
