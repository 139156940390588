import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store"
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

Vue.use(VueRouter);

const publicGuard = true, authGuard = true //, is_onboarded = false

// https://localhost:8080/shopify/?hmac=56564bd6fea303d4c756db41fc03bdb19132e7bc2ed726732463ab8a70e647fd&shop=shopfulfiller.myshopify.com&timestamp=1638099026

const routes = [
  {
    path: '/shopify',
    name: 'shopify',
    // component: () => import('@/views/ShopifyOauth')
  },
  {
    path: '/redirect',
    name: 'redirect',
    // component: () => import('@/views/SignIn')
  },
  {
    path: '/auth',
    component: () => import(process.env.VUE_APP_MODE === 'postnord' ? '@/views/postnord/Index' : '@/views/Index'),
    meta: { authGuard },
    children: [
      // Dashboard
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: () => {
          return import(process.env.VUE_APP_MODE === 'postnord' ? '@/views/postnord/Dashboard' : '@/views/Dashboard')
        },
        meta: { authGuard },
      },
      {
        name: 'Fulfill',
        path: 'fulfill',
        component: () => import('@/views/FulfillView'),
        meta: {
          scope: 'fulfill',
          authGuard,
          forbidden: process.env.VUE_APP_MODE === 'postnord'
        },
      },
      {
        name: 'Logs',
        path: 'logs',
        component: () => import('@/views/LogsView'),
        meta: {
          scope: 'logs',
          authGuard,
        }
      },
      {
        name: 'Plans & Pricing',
        path: 'plans',
        component: () => import('@/views/PlansView'),
        meta: {
          scope: 'plans',
          authGuard,
        }
      },
      {
        name: 'Shipments',
        path: 'shipments',
        component: () => import('@/views/ShipmentsView'),
        meta: {
          scope: 'shipments',
          authGuard,
        }
      },
      {
        name: 'Support',
        path: 'support',
        component: () => import('@/views/SupportView'),
        meta: {
          // scope: 'support',
          authGuard,
        }
      },
      // {
      //   name: 'Printer Client',
      //   path: 'printer',
      //   component: () => import('@/views/PrinterView'),
      // },
      {
        name: 'Settings',
        path: 'settings',
        component: () => import('@/views/SettingsView'),
        meta: {
          scope: 'settings.*',
          authGuard,
        },
        children: [
          {
            name: 'Fulfillment & Products',
            path: 'company',
            component: () => import('@/components/SettingsCompanyCard'),
            meta: {
              scope: 'settings.company',
              authGuard,
            }
          },
          {
            name: 'Stores',
            path: 'stores',
            component: () => import('@/components/SettingsDataSources'),
            meta: {
              scope: 'settings.stores',
              authGuard,
            }
          },
          {
            name: 'Carriers',
            path: 'carriers',
            component: () => import('@/components/SettingsCarriers'),
            // props: route => ({show_dialog: route.query.showDialog}),
            meta: {
              scope: 'settings.carriers',
              authGuard,
            }
          },
          {
            name: 'Printer map',
            path: 'printers',
            component: () => import('@/components/SettingsPrinterCard'),
            meta: {
              scope: 'settings.printers',
              authGuard,
            }
          },
          {
            name: 'Users',
            path: 'users',
            component: () => import('@/components/SettingsUserCard'),
            meta: {
              scope: 'settings.users',
              authGuard,
            }
          },
        ]
      },
      {
        // name: 'Warehouse',
        path: 'warehouse',
        component: () => import('@/views/Warehouse'),
        meta: {
          scope: 'warehouse',
          authGuard,
        },
        children: [
          {
            name: 'Warehouse',
            path: '/',
            component: () => import('@/views/WarehouseOverview'),
            meta: {
              scope: 'warehouse',
              authGuard,
            },
          },
          {
            name: 'Shelve',
            path: 'shelve/:shelve_index',
            component: () => import('@/views/ShelveView'),
            meta: {
              sub_page: true,
              parent: 'Warehouse',
              scope: 'warehouse',
              authGuard,
            }
          },
        ]
      },
    ]
  },
  {
    path: '/signin',
    component: () => import('@/views/SignIn'),
    meta: {
      publicGuard,
    }
  },
  {
    path: '/password',
    component: () => import('@/views/PasswordReset'),
    meta: {
      publicGuard,
    }
  },
  {
    path: '/signup',
    component: () => import('@/views/SignIn'),
    meta: {
      publicGuard,
      signup: true,
    }
  },
  {
    path: '/pricing',
    component: () => import('@/views/PlansView'),
    name: 'Plans'
  },
  {
    path: '/privacy',
    component: () => import('@/views/PrivacyView'),
    name: 'Privacy Policy'
  },
  {
    path: '/support',
    component: () => import('@/views/SupportView'),
    name: 'Support & FAQ'
  },
  {
    path: "*",
    name: "catchAll",
    meta: {
      publicGuard,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});


router.beforeEach(async (to, from, next) => {
  console.log('TO', to)
  if(to.name === 'catchAll') return next(`${store.state.user.user && '/auth/dashboard' || '/signIn'}${to.fullPath.split('?')[1] ? `?${to.fullPath.split('?')[1]}` : ''}`)
  if(to.name === 'shopify') {
    if(store.state.user.user){
      console.log('signing out');
      store.dispatch('app/reset_state')
      await firebase.auth().signOut()
    }
    // console.log(to);
    store.commit('app/SET_STATE', { loading: true, loading_message: 'Loading...' })
    const res = await store.dispatch('user/validate_shopify_request', to.query)
    if(res.code){
      // installation flow
      const url = `https://${to.query.shop}/admin/oauth/authorize?client_id=${process.env.VUE_APP_SHOPIFY_CLIENT_ID}&scope=${process.env.VUE_APP_SHOPIFY_SCOPES}&redirect_uri=${window.location.origin}/redirect&state=${res.code}`
      return window.open(url, '_top')
    } else if (res.user){
      // sign in
      store.commit('app/SET_STATE', { loading: true })
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      await firebase.auth().signInWithCustomToken(res.session_token)
      const { user, company } = res
      store.commit('user/SET_USER_PROFILE', user)
      store.commit('warehouse/INIT_COMPANY', { company })
      store.dispatch('warehouse/get_company_plan').then(() => {
        store.dispatch('order/get_order_count')
        store.dispatch('product/get_products', {})
      })
      store.commit('app/SET_STATE', { loading: false })
      next({path: '/auth/dashboard'})
    } else {
      // error
      next({path: '/'})
    }
  }
  else if(to.name === 'redirect') {
    // after setting oauth consent, install app 
    store.commit('app/SET_STATE', { loading: true, loading_message: 'Installing..' })
    const res = await store.dispatch('user/validate_shopify_request', to.query)
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    await firebase.auth().signInWithCustomToken(res.session_token)
    // await store.dispatch('user/set_custom_claims')
    // await firebase.auth().currentUser.getIdToken(true)
    // const user = await store.dispatch('user/get_user', {})
    const { user, company } = res
    store.commit('user/SET_USER_PROFILE', user)
    store.commit('warehouse/INIT_COMPANY', { company })
    store.dispatch('warehouse/get_company_plan').then(() => {
      store.dispatch('order/get_order_count')
      store.dispatch('product/get_products', {})
    })
    store.commit('app/SET_STATE', { loading: false })
    next({path: '/auth/dashboard'}) 
  }
  else if(to.query.charge_id) {
    console.log(to.query.charge_id);
    store.commit('app/SET_STATE', { loading: true, loading_message: 'Changing plan..' })
    const {session_token, user, company } = await store.dispatch('app/get_subscription', to.query.charge_id)
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    console.log('session_token', session_token)
    await firebase.auth().signInWithCustomToken(session_token)
    store.commit('user/SET_USER_PROFILE', user)
    store.commit('warehouse/INIT_COMPANY', { company })
    store.dispatch('warehouse/get_company_plan').then(() => {
      store.dispatch('order/get_order_count')
      store.dispatch('product/get_products', {})
    })
    store.commit('app/SET_STATE', { loading: false })
    next({path: '/auth/plans'}) 

  }
  else if(to.meta.authGuard){
    if(!store.state.user.user) return next({ path: '/signin' })
    if(to.meta.forbidden) return next('/dashboard')
    if(!store.state.user.userProfile){
      console.log('init user load');
      store.commit('app/SET_STATE', { loading: true })
      const companies = await firebase.auth().currentUser.getIdTokenResult().then(res => res.claims.company_ids)
      const [user_profile, company] = await Promise.all([
        firebase.firestore().collection('users').doc(store.state.user.user.uid).get().then(res => res.data()),
        companies && companies.length ? firebase.firestore().collection('companies').doc(companies[0]).get().then(res => {
          const rest = res.data()
          delete rest.order_buckets
          return rest
        }) : null
          
      ])
      store.commit('user/SET_USER_PROFILE', user_profile)
      if(company) {
        store.commit('warehouse/INIT_COMPANY', { company })
        if(company.printserver_id) store.dispatch('warehouse/set_printserver_listener')
        const company_plan = store.dispatch('warehouse/get_company_plan')
        await Promise.all(
          company.data_sources.map(ds => firebase.firestore().collection('data_sources').doc(ds).get()
            .then(res => {
              const data_source = res.data()
              delete data_source.token
              delete data_source.subscription_plan
              store.commit('warehouse/SET_DATA_SOURCE', { id: ds, ...data_source})
              if(to.query.state && data_source.shopify_last_known_state_uid === to.query.state) store.dispatch('warehouse/save_data_source', { id: data_source.id, shopify_scopes_last_updated_at: new Date().toISOString().substring(0,10) })
              return data_source
            })
        )
        )
      company_plan.then(() => {
        store.dispatch('order/get_order_count')
        store.dispatch('product/get_products', {})
      })
      }
      store.commit('app/SET_STATE', { loading: false, loading_message: '' })
    }
    if(to.meta && to.meta.scope && !store.getters['user/get_user_scope'](to.meta.scope)) { 
      console.log('nav guard to dashboard'); // Vi har inte user ännu... 
      return next({ path: '/auth/dashboard' })
    } else return next()
    

  }
  // else if(to.meta && to.meta.scope && !store.getters['user/get_user_scope'](to.meta.scope)) { 
  //   console.log('nav guard to dashboard'); // Vi har inte user ännu... 
  //   return next({ path: '/auth/dashboard' })
  // }
  else if (to.meta.authGuard && !store.state.user.user) next({ path: '/signin' })
  else if (to.meta.publicGuard && !!store.state.user.user) next({ path: '/auth/dashboard'})
  else next()
})

export default router;
