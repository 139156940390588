<template>
  <div>
    <v-text-field
      v-if="type !== 'phone'"
      :value="value"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :messages="messages"
      :append-icon="isPassword || appendIcon"
      :readonly="readonly"
      :required="required"
      :error-messages="errorMessages"
      :type="inputType"
      :validate-on-blur="validateOnBlur"
      ref="form"
      @input="$emit('input', $event)"
      @click:append="clickAppend($event)"
      @blur="$emit('blur')"
      outlined
      dense
    ></v-text-field>
      <vue-tel-input-vuetify
      v-else
      :value="value"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :messages="messages"
      :append-icon="isPassword || appendIcon"
      :readonly="readonly"
      :required="required"
      :error-messages="errorMessages"
      :type="inputType"
      ref="form"
      @input="onPhoneInput"
      @click:append="clickAppend($event)"
      @blur="$emit('blur')"
      outlined
      dense
    ></vue-tel-input-vuetify>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    placeholder: String,
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    errorMessages: {
      type: Array, 
      required: false, 
      default: () => [],
    },
    required: {
      type: Boolean,
      required: false, 
      default: false,
    },
    readonly: {
      type: Boolean, 
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    messages: {
      type: String,
      default: ''
    },
    value: {},
    validateOnBlur: {
      type: Boolean,
      default: true,
      required: false,
    },
    rules: {
      type: Array,  
      required: false,
      default: () => [true]
      },
    appendIcon: {
      type: String, 
      default: '',
      required: false
    }
  },
  data () {
    return {
      password: false,
      showPassword: false,
      inputType: 'text',
      phone: null
    }
  },
  mounted(){
    if(this.type === 'password') this.password = true;
    this.inputType = this.type;
    if(this.type === 'phone') this.phone = this.value;
  },
  methods: {
    onPhoneInput(formattedNumber, { number, valid }) {
      this.$emit('input', number.input)
      this.$emit('validated', valid && number.e164)

    },
    clickAppend (e){
      if(!this.password) return this.$emit('click:append', e)
      this.showPassword = !this.showPassword;
      this.inputType = this.showPassword ? 'text' : 'password'
    }
  },
  computed: {
    isPassword(){
      if(!this.password) return null
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off' 
    }
  }
}
</script>