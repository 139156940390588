import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from 'vuetify/lib/locale/en';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { en },
      current: 'en',
    },
    theme: {
      themes: {
        light: {
          primary: colors.green,
          secondary: colors.green.darken2,
          background: '#f6f6f7',
        }
      }
    }
});
