import Vue from 'vue'


const initial_state = () => ({ 
    user: null,
    userProfile: null,
})

const state = initial_state()


const actions = {
  // async create_user({ commit }, { id, email, name }){
  //   console.log('user creation');
  //   const create_user = this._vm.$functions.httpsCallable('create_user') 
  //   const user = await create_user({ id, email, name }).then (res => res.data)
  //   console.log('user created', state.user);
  //   commit('SET_USER_PROFILE', user)
  // },
  async invite_user({ rootState, commit }, user){
      const invite_user = this._vm.$functions.httpsCallable('invite_user') 
      const company_id = rootState.warehouse.current_company_id
      const users = await invite_user({ user, company_id }).then(res => res.data)
      commit('warehouse/SET_COMPANY', users, { root: true })
  },
  async set_user_scopes({ rootState }, { id, scopes, is_invitation }){
    const save_user_scopes = this._vm.$functions.httpsCallable('save_user_scopes') 
    const company_id = rootState.warehouse.current_company_id
    await save_user_scopes({ id, scopes, company_id, is_invitation })
    return true
  },
  async get_company_users({ rootState, commit }){
    if(rootState.warehouse.company.users.some(user => typeof user === 'string')) {
      const get_company_users = this._vm.$functions.httpsCallable('get_company_users') 
      const users = await get_company_users({ company_id: rootState.warehouse.current_company_id })
        .then(res => res.data)
      commit('warehouse/SET_COMPANY', { users }, { root: true })
    } 
  },
  async validate_shopify_request({ state }, query){
    console.log('useless', state.user)
    const validate_shopify_request = this._vm.$functions.httpsCallable('validate_shopify_request')  
    const user = await validate_shopify_request(query).then(res => res.data)
    // if(user && user.user) {
    //   commit('SET_USER', user)
    // }
    return user
  },
async set_custom_claims({ state }){
  const set_user_claims = this._vm.$functions.httpsCallable('set_user_claims') 
  await set_user_claims({id: state.user.uid })
},
 async get_user({ commit, state }, { name, email }){
  try {
    commit('app/SET_STATE', { loading_message: 'Loading user...' }, { root: true })  
    if(state.userProfile) return state.userProfile
    const get_user = this._vm.$functions.httpsCallable('get_userProfile')
    const res = await get_user({id: state.user.uid, name, email }).then(res => res.data)
    commit('SET_USER_PROFILE', res)
    return res
  } catch (e) {
    this.commit('app/SET_SNACK_BAR', e.message)
  }
 }

}

const mutations = {
  SET_USER(state, user){
    state.user = user
  },
SET_USER_PROFILE(state, userProfile){
  state.userProfile = userProfile
  },
  RESET_STATE(state){
    for(const key in initial_state()){
      Vue.set(state, key, initial_state()[key])
    }
  }
}

const getters = {
  get_user_scope: (state, getters, rootState) => (scope) => {
    return state.user && rootState.warehouse?.company
      && (
      state.user.uid === rootState.warehouse.company.owner ||
      state.userProfile.companies
          .some(company => (company.id === rootState.warehouse.current_company_id) 
          &&
            scope.indexOf('*') !== -1 ? 
              company.scopes.some(s => s.split('.')[0] === scope.split('.')[0]) : 
              company.scopes.indexOf(scope) !== -1
          )
          
        )
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
