import Vue from "vue"
import firebase from 'firebase/app'
import 'firebase/firestore';

const initial_state = () => ({ 
    carriers: {},
    service_charges: {},
})

const state = initial_state()

const actions = {
  async get_service_charges({ commit }, carrier){
    const service_charges = await firebase.firestore().collection('service_charges')
      .where('carrier', '==', carrier)
      .get()
      .then(r => r.docs.map(t => t.data()))
    commit('SET_SERVICE_CHARGE', { carrier, service_charges })
  },
  async get_shipping_zones({ commit, rootState, dispatch }, ds_id){
    const get_shipping_zones = this._vm.$functions.httpsCallable('get_shipping_zones')
    const data = await get_shipping_zones( {
      company_id: rootState.warehouse.current_company_id,
      ds_id,
    }).then(res => res.data)
    const { shipping_map } = rootState.warehouse.company.data_sources.find(ds => ds.id === ds_id)
    const new_shipping_map = shipping_map
      .filter(service => data
          .some(existing_service => existing_service.title === service.title)
      )
    if(new_shipping_map.length !== shipping_map.length) await dispatch('warehouse/save_data_source', {
      id: ds_id,
      shipping_map: new_shipping_map,
    }, { root: true })
    commit('SET_SHIPPING_ZONE', { id: ds_id, data })
  },
  async get_carrier_partners({ commit, rootState }, carrier_id){
    const get_carrier_partners = this._vm.$functions.httpsCallable('get_carrier_partners')
    const carrier = await get_carrier_partners({
      company_id: rootState.warehouse.current_company_id,
      carrier_id
    }).then(res => res.data)
    commit('SET_CARRIER', { carrier, carrier_id })
  }
}

const mutations = {
    SET_SERVICE_CHARGE(state, { carrier, service_charges }){
      Vue.set(state.service_charges, carrier, service_charges)
    },
    SET_SHIPPING_ZONE(state, { id, data}){
      Vue.set(state, id, data)
    },
    SET_CARRIER(state, { carrier, carrier_id }){
      console.log('trying set carrier', carrier)
      Vue.set(state.carriers, carrier_id, carrier)
    },
    RESET_STATE(state){
      for(const key in initial_state()){
        Vue.set(state, key, initial_state()[key])
      }
    }
}

const getters = {
  get_unmapped_zones: (state, getters, rootState) => (store_id) => {
    if(!state[store_id]) return []
    const types = JSON.parse(JSON.stringify(state[store_id])) // yea, becomes infinte loop otherwise.. 
    const store_map = rootState.warehouse.company.data_sources
      .filter(ds => ds.id === store_id)[0].shipping_map
    for(const type of store_map){
      const index = types.findIndex(a => a.title === type.title)
      if(index === -1) continue
      types[index].countries = types[index].countries
        .filter(country => type.countries.indexOf(country) === -1)
    }
    return types.filter(a => !!a.countries.length)
  },
  get_available_methods: (state) => ({carrier_account_id, carrier_id, country}) => {
    if(!state.carriers[carrier_account_id]) return []
    const to = country
    const carrier = state.carriers[carrier_account_id].filter(carrier => carrier.id === carrier_id)[0]
    const ret = []
    // for(const carrier of carriers){
      for(const service of carrier.services){
        const routes = service.routes.reduce((tot, route) => tot.concat(route.to), [])
        const addons = service.addons.filter(addon => {
          if(!addon.routes) return true
          const addon_routes = addon.routes.reduce((tot, route) => tot.concat(route.to), [])
          if(addon_routes.indexOf(country) === -1) return false
          return true
        })
        if(routes.indexOf(to) !== -1) ret.push({...service, addons})
      }
    // }
    return ret
  }
}

export default {
    state,
    getters,
    actions, 
    mutations,
    namespaced: true,
}
